<template>
  <div class="flex h-full">
    <div class="flex h-full flex-1">
      <div class="flex h-full w-1/2 flex-col">
        <AlertsCustomCreateIndex :alert="alert" :entity="entity" />
      </div>
      <div class="flex flex-1 flex-col space-y-2 overflow-hidden border-l border-grid">
        <AlertsCustomVisualizationIndex :alert="alert" :entity="entity" class="h-5/6 overflow-auto" />
        <div class="flex h-20 w-full items-center justify-between border-t border-grid px-6 py-4">
          <BaseButton v-if="!alert?.id" @press="handleReset" type="secondary">Reset</BaseButton>
          <BaseButton @press="saveAlert()" :disabled="disabled" class="ml-auto">
            {{ alert?.id ? 'Update' : 'Create' }}
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import useEmitter from '@/composeables/emitter';
import { computed, onMounted, ref, onBeforeUnmount } from 'vue';
import AlertsCustomCreateIndex from '@/components/alerts/custom/create/AlertsCustomCreateIndex.vue';
import AlertsCustomVisualizationIndex from '@/components/alerts/custom/visualization/AlertsCustomVisualizationIndex.vue';

const store = useStore();
const emitter = useEmitter();

const props = defineProps({
  alert: { type: Array, default: null }
});

const disabled = ref(false);

onMounted(async () => {
  store.dispatch('setUnlockCoins');
  emitter.$on('enable:alert-button', () => {
    disabled.value = false;
  });
});

onBeforeUnmount(() => {
  emitter.$off('enable:alert-button');
});

const entity = computed(() => {
  return props.alert && props.alert.entity ? props.alert.entity : 'Coin';
});

function saveAlert() {
  disabled.value = true;
  emitter.$emit('save:alert');
}

function handleReset() {
  emitter.$emit('clear:alert-type');
  emitter.$emit('clear:alert');
}
</script>
